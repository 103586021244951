import {
  required,
  integerDouble,
  max,
} from '@validations'

import {
  BRow,
  BCol,
  BCollapse,
  BOverlay,
  BCard,
  BCardBody,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BLink,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
  VBTooltip,

  BCardHeader,
  BFormRadio,
  BButton,
} from 'bootstrap-vue'

import ItemBadge from '@/components/ItemBadge.vue'
import CardYoutubeLinks from '@/components/card/CardYoutubeLinks.vue'
import IconPreview from '@/components/icon/IconPreview.vue'
import ModalPreview from '@/components/modal/ModalPreview.vue'
import CardImages from '@/components/card/CardImages.vue'
import CardTags from '@/components/card/CardTags.vue'
import Translate from '@/components/Translate.vue'
import CardSuggestions from '@/components/card/CardSuggestions.vue'
import CardPricing from '@/components/card/CardPricing.vue'
import IconTranslate from '@/components/icon/IconTranslate.vue'
import IconStopList from '@/components/icon/IconStopList.vue'
import ModalConfirmDelete from '@/components/modal/ModalConfirmDelete.vue'

import { mapGetters, mapActions } from 'vuex'
import Treeselect from '@riophae/vue-treeselect'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import mixinRequests from '@/mixins/requests'
import mixinTranslationsCreateUpdate from '@/mixins/translations_create-update'
import mixinFormatter from '@/mixins/formatter'
import mixinGoogleTranslation from '@/mixins/google-translation'

import UPDATE_MENU_ITEM from '@/gql/mutation/menuItem/updateMenuItem.gql'
import DUPLICATE_MENU_ITEM from '@/gql/mutation/menuItem/duplicateMenuItem.gql'
import DELETE_MENU_ITEM from '@/gql/mutation/menuItem/deleteMenuItem.gql'
import CREATE_MENU_ITEM_TRANSLATION from '@/gql/mutation/menuItem/createMenuItemTranslation.gql'
import UPDATE_MENU_ITEM_TRANSLATION from '@/gql/mutation/menuItem/updateMenuItemTranslation.gql'
import GET_MENU_ITEM_TRANSLATIONS from '@/gql/query/menu-managment/getMenuItemTranslations.gql'

export default {
  mixins: [
    mixinRequests,
    mixinTranslationsCreateUpdate,
    mixinGoogleTranslation,
    mixinFormatter,
  ],
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BCollapse,
    BOverlay,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BLink,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    VBTooltip,

    BCardHeader,
    BFormRadio,
    BButton,

    ItemBadge,
    CardYoutubeLinks,
    IconPreview,
    ModalPreview,
    CardImages,
    CardTags,
    Translate,
    CardSuggestions,
    CardPricing,
    IconStopList,
    IconTranslate,
    ModalConfirmDelete,

    BCardActions,
    Treeselect,

    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      isDuplicating: false,
      isFetching: true,

      isEnabled: true,
      isVisible: true,

      name: '',
      description: '',
      longDescription: '',

      offers: [],

      videos: [],
      images: [],

      tags: [],

      translations: [],
      translationActive: '',
      isTranslateMode: false,

      suggestionName: '',
      suggestionItems: [],

      badge: '',

      isCollapsedCardPreparation: true,
      preparation: {
        time: null,
        isInMenus: false,
      },

      items: [],

      textLimitShort: 250,
      textLimitFull: 500,
      textLimitSuggestions: 100,

      isCollapsedCardYoutubeLinks: true,
      isCollapsedCardImages: true,
      isCollapsedCardSuggestions: true,
      isCollapsedCardTags: true,

      isModalConfirmDelete: false,
      isModalPreview: false,

      createTranslationResponseObjectName: 'createMenuItemTranslation',
      createTranslationMutation: CREATE_MENU_ITEM_TRANSLATION,
      updateTranslationMutation: UPDATE_MENU_ITEM_TRANSLATION,

      // validation rules
      required,
      integerDouble,
      max,
    }
  },
  computed: {
    ...mapGetters({
      isMenusFetched: 'menuManagement/isMenusFetched',
      isMenuItemsFetched: 'menuManagement/isMenuItemsFetched',
      restaurant: 'restaurant/restaurant',
      menuItems: 'menuManagement/menuItems',
      menus: 'menuManagement/menus',
      isInfo: 'main/isInfo',
      subscription: 'billing/subscription',
      currencySymbol: 'restaurant/currencySymbol',
      isSubscriptionInactiveOrBasic: 'billing/isSubscriptionInactiveOrBasic',
      isSubscriptionPremium: 'billing/isSubscriptionPremium',
    }),
    previewUrl() {
      if (!this.offers.length) {
        return ''
      }

      const offer = this.offers[this.offers.length - 1]
      const queryParams = `restaurant=${this.restaurant ? this.restaurant.id : null}&menu=${offer.id}`
      return `${process.env.VUE_APP_ORIGIN_CLIENT}/preview/item/${offer.categories[0].items[0].id}?${queryParams}`
    },
    optionsTreeselectItems() {
      return this.menuItems
        .filter(i => i.type === 'MenuItem')
        .map(i => ({ ...i, label: i.name }))
    },
  },
  watch: {
    isMenusFetched() {
      this.fetch()
    },
    isMenuItemsFetched() {
      this.fetch()
    },
    $route: {
      immediate: true,
      handler() {
        this.fetch()
      },
    },
  },
  methods: {
    ...mapActions({
      setNavHistory: 'main/setNavHistory',
      getMenuItems: 'menuManagement/getMenuItems',
      setMenuItems: 'menuManagement/setMenuItems',
      setIsInfo: 'main/setIsInfo',
      getMenus: 'menuManagement/getMenus',
      updateMenuItem: 'menuManagement/updateMenuItem',
    }),

    updateHistory() {
      const history = ['item', this.$route.params.id]
      this.$emit('setLastOpened', history)
      this.setNavHistory(history)
    },
    async getTranslations(menuItemId) {
      const response = await this.$apollo.query({
        query: GET_MENU_ITEM_TRANSLATIONS,
        variables: {
          getMenuItemTranslationsId: menuItemId,
        },
      })
      return response.data.getMenuItemTranslations
    },
    setMainData(menuItem) {
      this.isEnabled = menuItem.isEnabled
      this.isVisible = menuItem.isVisible

      this.name = menuItem.name
      this.description = menuItem.description || ''
      this.longDescription = menuItem.longDescription || ''
      this.badge = menuItem.badge || ''

      this.setOffers()

      // eslint-disable-next-line no-undef
      this.items = structuredClone(menuItem.items)
      if (
        (
          this.items.length
          && this.$refs['card-consist']
          && !this.$refs['card-consist'].$el.getElementsByClassName('card-content collapse')[0].classList.contains('show')
        ) || (
          !this.items.length && this.$refs['card-consist']
          && this.$refs['card-consist'].$el.getElementsByClassName('card-content collapse')[0].classList.contains('show')
        )
      ) {
        this.$refs['card-consist'].$el.getElementsByClassName('card-header')[0].click()
      }

      this.videos = [...menuItem.videos]
      this.isCollapsedCardYoutubeLinks = !this.videos.length

      this.images = [...menuItem.images]
      this.isCollapsedCardImages = !this.images.length

      this.suggestionName = menuItem.suggestionName
      // eslint-disable-next-line no-undef
      this.suggestionItems = structuredClone(menuItem.suggestionItems)
      this.isCollapsedCardSuggestions = !this.suggestionItems.length

      this.preparation.time = menuItem.preparationTime || ''
      this.preparation.isInMenus = !!menuItem.showPreparationTime
      this.isCollapsedCardPreparation = !(this.preparation.time || this.preparation.isInMenus)

      // eslint-disable-next-line no-undef
      this.tags = structuredClone(menuItem.tags)
      this.isCollapsedCardTags = !this.tags.length
    },

    getTranslationPayload(i) {
      return {
        language: i.language,

        name: i.name || null,
        badge: i.badge || null,
        description: i.description || null,
        longDescription: i.longDescription || null,
        suggestionName: i.suggestionName || null,

        tags: i.tags.map(j => ({ ...j, name: j.name || null })),
        options: i.options.map(j => ({
          ...j,
          name: j.name || null,
          items: j.items
            .filter((k, kIndex) => kIndex + 1 !== j.items.length)
            .map(k => ({ ...k, name: k.name || null })),
        })),
        extras: i.extras.map(j => ({
          ...j,
          name: j.name || null,
          items: j.items.map(k => ({ ...k, name: k.name || null })),
        })),
      }
    },
    getTranslationPayloadClear(language) {
      return {
        language,

        name: null,
        badge: null,
        description: null,
        longDescription: null,
        suggestionName: null,

        tags: this.tags.map(i => ({ id: i.id, name: null })),
        options: this.options
          ? this.options.map(i => ({
            id: i.id,
            name: null,
            type: i.type,
            items: i.items.map(j => ({ id: j.id, name: null })),
          }))
          : [],
        extras: this.extras
          ? this.extras.map(i => ({
            id: i.id,
            name: null,
            sameAddPrice: i.sameAddPrice,
            items: i.items.filter(j => j.type === 'SimpleItem').map(j => ({ id: j.id, name: null })),
          }))
          : [],
      }
    },
    onClearTranslation() {
      this.translations = this.translations.map(i => {
        if (i.language === this.translationActive) {
          return {
            ...i,

            name: null,
            badge: null,
            description: null,
            longDescription: null,
            suggestionName: null,

            tags: i.tags.map(j => ({ ...j, name: null })),
            options: i.options.map(j => ({
              ...j,
              name: null,
              items: j.items.map(k => ({ ...k, name: null })),
            })),
            extras: i.extras.map(j => ({
              ...j,
              name: null,
              items: j.items.map(k => ({ ...k, name: null })),
            })),
          }
        }
        return i
      })

      this.patchTranslationActive()
    },

    onResetOffers() {
      this.setOffers()
    },
    setOffers() {
      let result = []

      this.menus.forEach(i => {
        i.categories.forEach(j => {
          j.items.forEach(k => {
            if (k.item.id === this.$route.params.id) {
              result = [
                ...result,
                {
                  ...i,
                  categories: [
                    {
                      ...j,
                      items: [
                        {
                          ...k,
                          price: this.getFormattedPrice(k.price),
                        },
                      ],
                    },
                  ],
                },
              ]
            }
          })
        })
      })

      // eslint-disable-next-line no-undef
      this.offers = structuredClone(result)
    },

    openPreviewModal() {
      if (!this.offers.length) {
        return
      }

      this.isModalPreview = true
    },
    setTranslateMode(event) {
      this.isTranslateMode = event
      this.$emit('setSidebarState', !event)
    },

    patchBase(name, value) {
      return [
        this.$apollo.mutate({
          mutation: UPDATE_MENU_ITEM,
          variables: {
            updateMenuItemId: this.$route.params.id,
            [name]: value,
          },
        }),
      ]
    },

    patchEnabled() {
      return this.patchBase('isEnabled', this.isEnabled)
    },
    onClickEnabled() {
      this.isEnabled = !this.isEnabled
      this.updateMenuItem([this.$route.params.id, 'isEnabled', this.isEnabled])
      this.requests.push('patchEnabled', () => [this.getMenus(), this.getMenus()])
    },
    patchVisible() {
      return this.patchBase('isVisible', this.isVisible)
    },
    onClickVisible() {
      this.isVisible = !this.isVisible
      this.updateMenuItem([this.$route.params.id, 'isVisible', this.isVisible])
      this.requests.push('patchVisible', () => [this.getMenus(), this.getMenus()])
    },

    patchName() {
      return this.patchBase('name', this.name)
    },
    async onBlurName(refName) {
      if (this.name === this.menuItems.find(i => i.id === this.$route.params.id).name) {
        return
      }

      const isValid = await this.$refs[refName].validate()
      if (!isValid) {
        return
      }

      this.updateMenuItem([this.$route.params.id, 'name', this.name])

      this.requests.push('patchName', () => [this.getMenus(), this.getMenuItems()])
    },
    patchDescription() {
      return this.patchBase('description', this.description)
    },
    async onBlurDescription(refName) {
      const isValid = await this.$refs[refName].validate()
      if (isValid) {
        this.updateMenuItem([this.$route.params.id, 'description', this.description])
        this.requests.push('patchDescription')
      }
    },
    patchLongDescription() {
      return this.patchBase('longDescription', this.longDescription)
    },
    async onBlurLongDescription(refName) {
      const isValid = await this.$refs[refName].validate()
      if (isValid) {
        this.updateMenuItem([this.$route.params.id, 'longDescription', this.longDescription])
        this.requests.push('patchLongDescription')
      }
    },
    patchBadge() {
      return this.patchBase('badge', this.badge)
    },
    onBlurBadge() {
      this.updateMenuItem([this.$route.params.id, 'badge', this.badge])
      this.requests.push('patchBadge')
    },
    onSaveBadge() {
      this.onBlurBadge()
    },

    patchVideos() {
      return this.patchBase('videos', this.videos)
    },
    onInputYoutubeLinks(event) {
      this.videos = [...event]
      this.updateMenuItem([this.$route.params.id, 'videos', this.videos])
      this.requests.push('patchVideos')
    },

    patchTags() {
      return this.patchBase('tags', this.tags.map(i => ({ id: i.id, name: i.name })))
    },
    patchTagsAndPatchTranslations() {
      return [
        ...this.patchTags(),
        ...this.patchTranslations(),
      ]
    },
    onInputTags(event) {
      if (event[0] === 'add') {
        this.tags = [...this.tags, { ...event[1] }]
        this.translations = this.translations
          .map(translation => ({ ...translation, tags: [...translation.tags, { id: event[1].id, name: '' }] }))
      }

      if (event[0] === 'remove') {
        this.tags = this.tags.filter(i => i.id !== event[1].id)
        this.translations = this.translations
          .map(translation => ({ ...translation, tags: translation.tags.filter(i => i.id !== event[1].id) }))
      }

      this.updateMenuItem([this.$route.params.id, 'tags', this.tags.map(j => ({ id: j.id, name: j.name }))])

      this.requests.push('patchTagsAndPatchTranslations')
    },

    onSaveSuggestionName() {
      this.updateMenuItem([this.$route.params.id, 'suggestionName', this.suggestionName])
      this.requests.push(() => this.patchBase('suggestionName', this.suggestionName))
    },
    async onBlurSuggestionName() {
      const isValid = await this.$refs.suggestionsName.validate()
      if (isValid) {
        this.onSaveSuggestionName()
      }
    },
    onSaveSuggestionItems() {
      this.updateMenuItem([this.$route.params.id, 'suggestionItems', this.suggestionItems])
      this.requests.push(() => this.patchBase('suggestionItems', this.suggestionItems.map(i => i.id)))
    },

    async onClickTranslateField(path) {
      await this.translateField(path)
      this.patchTranslationActive()
    },

    onInputImages(event) {
      this.images = event
      this.updateMenuItem([this.$route.params.id, 'images', this.images])
    },

    patchPreparationTime() {
      return this.patchBase('preparationTime', this.preparation.time)
    },
    onSavePreparationTime() {
      this.updateMenuItem([this.$route.params.id, 'preparationTime', this.preparation.time])
      this.requests.push('patchPreparationTime')
    },

    patchPreparationIsInMenus() {
      return this.patchBase('showPreparationTime', this.preparation.isInMenus)
    },
    onSavePreparationIsInMenus() {
      this.updateMenuItem([this.$route.params.id, 'showPreparationTime', this.preparation.isInMenus])
      this.requests.push('patchPreparationIsInMenus')
    },

    patchItems() {
      return this.patchBase('items', this.items.map(i => i.id))
    },
    saveItems() {
      this.updateMenuItem([this.$route.params.id, 'items', this.items])
      this.requests.push('patchItems')
    },
    onCloseTreeselectItems() {
      this.saveItems()
    },
    onClickDeleteItem(id) {
      this.items = this.items.filter(i => i.id !== id)
      this.saveItems()
    },

    showNotificationToFixValidation() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('Error'),
          text: this.$t('notificationErrorValidateData'),
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    },

    async onClickTranslateItem(type, id, itemId) {
      const value = this[type].find(i => i.id === id).items.find(i => i.id === itemId).name
      if (!value) return

      const response = await this.getGoogleTranslation([value])
      if (response) {
        const [responseValue] = response

        const translation = this.translations.find(i => i.language === this.translationActive)
        translation[type].find(i => i.id === id).items.find(i => i.id === itemId).name = responseValue

        this.patchTranslationActive()
      }
    },

    moveItem(collection, index, delta) {
      const deletedElements = this[collection].splice(index, 1)
      this[collection].splice(index + delta, 0, deletedElements[0])

      this.translations = this.translations.map(translation => {
        const copy = [...translation[collection]]
        const deletedElementsTranslation = copy.splice(index, 1)
        copy.splice(index + delta, 0, deletedElementsTranslation[0])
        return { ...translation, [collection]: copy }
      })

      this.requests.push(
        () => [
          ...this[`patch${collection.charAt(0).toUpperCase() + collection.slice(1)}`](),
          ...this.patchTranslations(),
        ],
      )
    },

    async onClickDuplicate() {
      this.isDuplicating = true

      try {
        const response = await this.$apollo.mutate({
          mutation: DUPLICATE_MENU_ITEM,
          variables: {
            duplicateMenuItemId: this.$route.params.id,
          },
        })

        await Promise.all([
          this.getMenus(),
          this.getMenuItems(),
        ])

        await this.$router.push({
          name: 'menu-item',
          params: {
            id: response.data.duplicateMenuItem.id,
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isDuplicating = false
      }
    },

    async onDelete() {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_MENU_ITEM,
          variables: {
            deleteMenuItemId: this.$route.params.id,
          },
        })

        await Promise.all([
          this.getMenus(),
          this.getMenuItems(),
        ])

        await this.$router.push({ name: 'menu-management' })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Success'),
            text: this.$t('notificationSuccessDeleted'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
