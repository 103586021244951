<template>
  <div style="margin-bottom: -2rem">
    <translate
      v-if="isTranslateMode"
      :translations="translations"
      :translation-active="translationActive"
      :title="name"
      @setTranslationActive="translationActive = $event"
      @setTranslateMode="setTranslateMode"
      @translate="translateAll"
      @clearTranslation="onClearTranslation"
    >
      <template v-slot:default>
        <b-card>
          <b-form-group :label="$t('Combination Item Name')">
            <validation-observer ref="voNameTranslation">
              <validation-provider
                #default="{ errors }"
                name="combination_item_name"
                rules="required"
              >
                <b-form-input
                  v-model="name"
                  :placeholder="$t('Combination Item Name')"
                  :state="errors.length ? false : null"
                  @blur="onBlurName('voNameTranslation')"
                />
              </validation-provider>
            </validation-observer>
          </b-form-group>
          <b-form-group :label="$t('Short Description')">
            <validation-observer ref="voDescriptionTranslation">
              <validation-provider
                #default="{ errors }"
                name="short_description"
                :rules="`max:${textLimitShort}`"
              >
                <b-form-input
                  v-model="description"
                  :placeholder="$t('Short Description')"
                  :state="errors.length ? false : null"
                  @blur="onBlurDescription('voDescriptionTranslation')"
                />
              </validation-provider>
            </validation-observer>
          </b-form-group>
          <b-form-group :label="$t('Full Description')">
            <validation-observer ref="voLongDescriptionTranslation">
              <validation-provider
                #default="{ errors }"
                name="full_description"
                :rules="`max:${textLimitFull}`"
              >
                <b-form-textarea
                  v-model="longDescription"
                  :placeholder="$t('Full Description')"
                  rows="3"
                  no-resize
                  :state="errors.length ? false : null"
                  @blur="onBlurLongDescription('voLongDescriptionTranslation')"
                />
              </validation-provider>
            </validation-observer>
          </b-form-group>
          <b-form-group
            :label="$t('menuItemLabelBadge')"
            class="mb-0"
          >
            <b-form-input
              v-model="badge"
              :placeholder="$t('menuItemPlaceholderBadge')"
              @blur="onBlurBadge"
            />
          </b-form-group>
        </b-card>
        <b-card-actions
          :title="$t('cardSuggestionsTitle')"
          action-collapse
        >
          <b-form-group
            :label="$t('cardSuggestionsNameLabel')"
            class="mb-0"
          >
            <validation-observer ref="suggestionsName">
              <validation-provider
                #default="{ errors }"
                name="suggestions_name"
                :rules="`max:${textLimitSuggestions}`"
              >
                <b-form-input
                  v-model="suggestionName"
                  :placeholder="$t('cardSuggestionsNamePlaceholder')"
                  :state="errors.length ? false : null"
                  @blur="onBlurSuggestionName"
                />
              </validation-provider>
            </validation-observer>
          </b-form-group>
        </b-card-actions>
        <b-card-actions
          v-if="tags.length"
          :title="$t('Tags')"
          action-collapse
        >
          <b-form-group
            v-for="(tag, tagIndex) in tags"
            :key="tag.id"
            :class="{'mb-0': tags.length === tagIndex + 1}"
          >
            <b-form-input
              v-model="tag.name"
              disabled
            />
          </b-form-group>
        </b-card-actions>
      </template>
      <template v-slot:translation="{ translation }">
        <b-card>
          <b-form-group :label="$t('Combination Item Name')">
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="translation.name"
                :disabled="isSubscriptionInactiveOrBasic"
                :placeholder="$t('Combination Item Name')"
                @blur="patchTranslationActive"
              />
              <b-input-group-append
                v-if="isSubscriptionPremium"
                is-text
              >
                <icon-translate
                  v-b-tooltip.hover.top="$t('Auto Translate')"
                  class="size-18"
                  role="button"
                  @click.native="onClickTranslateField('name')"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group label="Short Description">
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="translation.description"
                :disabled="isSubscriptionInactiveOrBasic"
                :placeholder="$t('Short Description')"
                @blur="patchTranslationActive"
              />
              <b-input-group-append
                v-if="isSubscriptionPremium"
                is-text
              >
                <icon-translate
                  v-b-tooltip.hover.top="$t('Auto Translate')"
                  class="size-18"
                  role="button"
                  @click.native="onClickTranslateField('description')"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group :label="$t('Full Description')">
            <b-input-group class="input-group-merge">
              <b-form-textarea
                v-model="translation.longDescription"
                :disabled="isSubscriptionInactiveOrBasic"
                :placeholder="$t('Full Description')"
                rows="3"
                no-resize
                @blur="patchTranslationActive"
              />
              <b-input-group-append
                v-if="isSubscriptionPremium"
                is-text
              >
                <icon-translate
                  v-b-tooltip.hover.top="$t('Auto Translate')"
                  class="size-18"
                  role="button"
                  @click.native="onClickTranslateField('longDescription')"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group
            :label="$t('menuItemLabelBadge')"
            class="mb-0"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="translation.badge"
                :disabled="isSubscriptionInactiveOrBasic"
                :placeholder="$t('menuItemPlaceholderBadge')"
                @blur="patchTranslationActive"
              />
              <b-input-group-append
                v-if="isSubscriptionPremium"
                is-text
              >
                <icon-translate
                  v-b-tooltip.hover.top="$t('Auto Translate')"
                  class="size-18"
                  role="button"
                  @click.native="onClickTranslateField('badge')"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-card>
        <b-card-actions
          :title="$t('cardSuggestionsTitle')"
          action-collapse
        >
          <b-form-group
            :label="$t('cardSuggestionsNameLabel')"
            class="mb-0"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="translation.suggestionName"
                :placeholder="$t('cardSuggestionsNamePlaceholder')"
                :disabled="isSubscriptionInactiveOrBasic"
                @blur="patchTranslationActive"
              />
              <b-input-group-append
                v-if="isSubscriptionPremium"
                is-text
              >
                <icon-translate
                  v-b-tooltip.hover.top="$t('Auto Translate')"
                  class="size-18"
                  role="button"
                  @click.native="onClickTranslateField('suggestionName')"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-card-actions>
        <b-card-actions
          v-if="translation.tags.length"
          :title="$t('Tags')"
          action-collapse
        >
          <b-form-group
            v-for="(tag, tagIndex) in translation.tags"
            :key="translation.language + '_' + tag.id"
            :class="{'mb-0': translation.tags.length === tagIndex + 1}"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="tag.name"
                @blur="patchTranslationActive"
              />
              <b-input-group-append
                v-if="isSubscriptionPremium"
                is-text
              >
                <icon-translate
                  v-b-tooltip.hover.top="$t('Auto Translate')"
                  class="size-18"
                  role="button"
                  @click.native="onClickTranslateField(`tags[${tagIndex}].name`)"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-card-actions>
      </template>
    </translate>
    <template v-else>
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <b-card>
          <b-row>
            <b-col>
              <validation-observer ref="voName">
                <validation-provider
                  #default="{ errors, failedRules }"
                  name="combination_item_name"
                  rules="required"
                >
                  <b-form-input
                    ref="name"
                    v-model="name"
                    :placeholder="$t('Combination Item Name')"
                    class="input-h input-h4"
                    :state="errors.length ? false : null"
                    @blur="onBlurName('voName')"
                  />
                  <small class="text-danger">
                    <template v-if="failedRules.required">{{ $t('validationErrorRequiredCombinationItemName') }}</template>
                    <template v-else>{{ errors[0] }}</template>
                  </small>
                </validation-provider>
              </validation-observer>
            </b-col>
            <b-col
              cols="auto"
              style="padding-top: 9px"
            >
              <div class="d-flex">
                <icon-stop-list
                  v-b-tooltip.hover.top="$t('Temporary Unavailable')"
                  :class="['mr-2 size-20', {'fill-danger': !isEnabled}]"
                  role="button"
                  @click.native="onClickEnabled"
                />
                <feather-icon
                  v-b-tooltip.hover.top="$t('Hide in Menus')"
                  class="mr-2"
                  :icon="isVisible ? 'EyeIcon' : 'EyeOffIcon'"
                  role="button"
                  size="20"
                  @click="onClickVisible"
                />
                <icon-preview
                  v-b-tooltip.hover.top="$t('Preview')"
                  role="button"
                  class="size-20 mr-2"
                  :style="{
                    opacity: offers.length ? 1 : 0.5,
                    cursor: offers.length ? 'pointer' : 'default',
                  }"
                  @click.native="openPreviewModal"
                />
                <icon-translate
                  v-b-tooltip.hover.top="$t('Translate')"
                  role="button"
                  class="size-20"
                  @click.native="setTranslateMode(true)"
                />
                <b-spinner
                  v-if="isDuplicating"
                  variant="primary"
                  class="spinner-20 ml-2"
                />
                <feather-icon
                  v-else
                  v-b-tooltip.hover.top="$t('Duplicate')"
                  class="ml-2"
                  icon="CopyIcon"
                  role="button"
                  size="20"
                  @click="onClickDuplicate"
                />
                <feather-icon
                  v-b-tooltip.hover.top="$t('Delete')"
                  class="ml-2 text-danger"
                  icon="TrashIcon"
                  size="20"
                  role="button"
                  @click="isModalConfirmDelete = true"
                />
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-overlay>
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <b-card no-body>
          <b-card-header>
            <i18n
              path="infoCombinationItem"
              tag="small"
            >
              <template v-slot:slot>
                <br><br>
              </template>
            </i18n>
          </b-card-header>
          <b-card-body>
            <b-form-group :label="$t('Short Description')">
              <validation-observer ref="voDescription">
                <validation-provider
                  #default="{ errors }"
                  name="short_description"
                  :rules="`max:${textLimitShort}`"
                >
                  <b-form-input
                    v-model="description"
                    :placeholder="$t('Short Description')"
                    :state="errors.length ? false : null"
                    @blur="onBlurDescription('voDescription')"
                  />
                  <small
                    v-if="errors.length"
                    class="text-danger"
                  >
                    {{ errors[0] }}
                  </small>
                  <div
                    v-else
                    class="text-right"
                  >
                    <small>{{ description.length }} / {{ textLimitShort }}</small>
                  </div>
                </validation-provider>
              </validation-observer>
            </b-form-group>
            <b-form-group :label="$t('Full Description')">
              <validation-observer ref="voLongDescription">
                <validation-provider
                  #default="{ errors }"
                  name="full_description"
                  :rules="`max:${textLimitFull}`"
                >
                  <b-form-textarea
                    v-model="longDescription"
                    :placeholder="$t('Full Description')"
                    rows="3"
                    no-resize
                    :state="errors.length ? false : null"
                    @blur="onBlurLongDescription('voLongDescription')"
                  />
                  <small
                    v-if="errors.length"
                    class="text-danger"
                  >
                    {{ errors[0] }}
                  </small>
                  <div
                    v-else
                    class="text-right"
                  >
                    <small>{{ longDescription.length }} / {{ textLimitFull }}</small>
                  </div>
                </validation-provider>
              </validation-observer>
            </b-form-group>
            <item-badge
              v-model="badge"
              @save="onSaveBadge"
            />
          </b-card-body>
        </b-card>
      </b-overlay>
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <card-pricing
          v-model="offers"
          @reset="onResetOffers"
        />
      </b-overlay>
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <b-card-actions
          ref="card-consist"
          :title="$t('combinationConsistTitle')"
          action-collapse
          collapsed
        >
          <template v-slot:after-title>
            <feather-icon
              icon="InfoIcon"
              role="button"
              size="20"
              style="vertical-align: top"
              class="ml-25 text-primary toggle-content"
              @click.stop="setIsInfo(['combination', 'consist'])"
            />
          </template>
          <template v-slot:default>
            <b-collapse v-model="isInfo.combination.consist">
              <small class="d-block mb-2">
                {{ $t('infoConsist') }}
              </small>
            </b-collapse>
            <treeselect
              v-model="items"
              v-b-tooltip.hover.top="$t('Select an existing item')"
              :multiple="true"
              :disable-branch-nodes="true"
              :options="optionsTreeselectItems"
              :placeholder="$t('Add Item')"
              :clearable="false"
              value-format="object"
              @close="onCloseTreeselectItems"
            />
            <b-row
              v-for="(item, itemIndex) in items"
              :key="item.id"
              class="mt-1"
            >
              <b-col>
                <b-form-group
                  v-if="itemIndex === 0"
                  :label="$t('combinationItemLabel')"
                  class="mb-0"
                />
                <b-link
                  :to="{
                    name: item.type === 'MenuItem' ? 'menu-item' : 'combination-menu-item',
                    params: { id: item.id },
                  }"
                >
                  {{ item.name }}
                </b-link>
              </b-col>
              <b-col
                cols="auto"
                class="d-flex align-items-center"
                :style="{ paddingTop: itemIndex === 0 ? '27px' : null }"
              >
                <feather-icon
                  size="20"
                  role="button"
                  icon="XCircleIcon"
                  @click="onClickDeleteItem(item.id)"
                />
              </b-col>
            </b-row>
          </template>
        </b-card-actions>
      </b-overlay>
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <card-youtube-links
          page="combination"
          :collapsed="isCollapsedCardYoutubeLinks"
          :value="videos"
          @input="onInputYoutubeLinks"
        />
      </b-overlay>
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <card-images
          :value="images"
          page="combination"
          :collapsed="isCollapsedCardImages"
          @input="onInputImages"
        />
      </b-overlay>
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <card-suggestions
          page="combination"
          :name="suggestionName"
          :items="suggestionItems"
          :collapsed="isCollapsedCardSuggestions"
          :text-limit="textLimitSuggestions"
          @inputName="suggestionName = $event"
          @inputItems="suggestionItems = $event"
          @saveName="onSaveSuggestionName"
          @saveItems="onSaveSuggestionItems"
        />
      </b-overlay>
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <card-tags
          page="combination"
          :collapsed="isCollapsedCardTags"
          :value="tags"
          @input="onInputTags"
        />
      </b-overlay>
    </template>
    <modal-confirm-delete
      v-model="isModalConfirmDelete"
      :title="`${$t('itemDeleteTitle')}`"
      :is-message="false"
      @delete="onDelete"
    />
    <modal-preview
      v-model="isModalPreview"
      :link="previewUrl"
      :title="`${$t('modalPreviewItemTitle')}`"
    />
  </div>
</template>

<script>
import mixinMenuItem from '@/mixins/menu-item'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CombinationMenuItem',
  mixins: [
    mixinMenuItem,
  ],
  methods: {
    async fetch() {
      if (!this.isMenusFetched || !this.isMenuItemsFetched) {
        return
      }

      this.isFetching = true

      this.updateHistory()

      const menuItem = this.menuItems.find(i => i.id === this.$route.params.id)
      if (!menuItem) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: this.$t('Not found'),
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        await this.$router.push({ name: 'menu-management' })
        return
      }

      const translations = await this.getTranslations(menuItem.id)
      this.setMainData(menuItem)
      await this.setTranslations(translations)

      if (this.$refs.name && this.$route.params.isFocusOnName) {
        this.$refs.name.focus()
      }

      this.isFetching = false
    },

    async translateAll() {
      const initialRouteName = this.$route.name
      const initialRouteId = this.$route.params.id
      const initialTranslationActive = this.translationActive

      const response = await this.getGoogleTranslation([
        this.name,
        this.description,
        this.longDescription,
        this.badge,
        this.suggestionName,
        ...this.tags.map(i => i.name),
      ])

      if (
        !response
        || initialRouteName !== this.$route.name
        || initialRouteId !== this.$route.params.id
        || initialTranslationActive !== this.translationActive
      ) {
        return
      }

      this.translations = this.translations.map(i => {
        if (i.language === this.translationActive) {
          let index = 4 // index of last element in main data (suggestionName)
          return {
            ...i,
            name: response[0],
            description: response[1],
            longDescription: response[2],
            badge: response[3],
            suggestionName: response[4],
            tags: i.tags.map(j => {
              index += 1
              return { ...j, name: response[index] }
            }),
          }
        }
        return i
      })

      this.patchTranslationActive()
    },
  },
}
</script>
