<template>
  <b-form-group
    :label="$t('menuItemLabelBadge')"
    class="mb-0"
  >
    <b-input-group>
      <b-form-input
        v-model="badge"
        :placeholder="$t('menuItemPlaceholderBadge')"
        @blur="onBlurBadge"
      />
      <b-input-group-append v-if="badgesToChoose.length">
        <b-dropdown
          :text="$t('menuItemLabelBadgeSelect')"
          variant="outline-primary"
          right
        >
          <div :class="['pt-25 px-50', badgesToChooseFiltered.length ? 'pb-50' : 'pb-25']">
            <b-form-input
              v-model="search"
              :placeholder="$t('menuItemPlaceholderBadgeSearch')"
            />
          </div>
          <b-dropdown-item
            v-for="i in badgesToChooseFiltered"
            :key="i"
            @click="onClickBadge(i)"
          >
            {{ i }}
          </b-dropdown-item>
        </b-dropdown>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>
<script>
import {
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
  },
  props: {
    value: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      search: '',
    }
  },
  computed: {
    ...mapGetters({
      menuItems: 'menuManagement/menuItems',
    }),
    badge: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
    badgesToChoose() {
      const badges = this.menuItems.map(i => i.badge).filter(i => !!i)

      return [...new Set(badges)]
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
        .filter(i => i !== this.value)
    },
    badgesToChooseFiltered() {
      return this.badgesToChoose.filter(i => i.toLowerCase().includes(this.search.toLowerCase()))
    },
  },
  methods: {
    onBlurBadge() {
      this.$emit('save')
    },
    onClickBadge(value) {
      this.badge = value
      this.$emit('save')
    },
  },
}
</script>
