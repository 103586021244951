<template>
  <div>
    <b-row
      v-for="(item, index) in val"
      :key="item.id"
      :class="[{ 'mb-50': val.length !== index + 1 }]"
    >
      <b-col>
        <b-form-input
          :id="`youtube-input_${item.id}`"
          v-model="item.url"
          :disabled="item.isValidating"
          :state="!item.isValid ? false : null"
          placeholder="https://youtu.be/jfKfPfyJRdk"
          @blur="onBlurInput(item.id)"
          @input="item.isValid = true"
        />
        <b-tooltip
          v-if="!isSubscriptionPremium"
          :target="`youtube-input_${item.id}`"
          triggers="focus"
        >
          <div class="pt-50 pb-50">
            <span>{{ $t('cardYoutubeLinksTooltipNotPremium') }}</span>
            <b-button
              block
              size="sm"
              class="mt-50"
              variant="primary"
              @click="$router.push({ name: 'billing' })"
            >
              {{ isSubscriptionInactive ? $t('Start Free Trial') : $t('Upgrade') }}
            </b-button>
          </div>
        </b-tooltip>
      </b-col>
      <b-col
        cols="auto"
        class="d-flex align-items-center"
      >
        <b-spinner
          v-if="item.isValidating"
          variant="primary"
          class="spinner-20"
        />
        <feather-icon
          v-else
          :class="[val.length === index + 1 ? 'feather-disabled' : null]"
          icon="XCircleIcon"
          role="button"
          size="20"
          class=""
          @click="onClickRemove(item.id)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormInput,
  BSpinner,
  BRow,
  BCol,
  BButton,
  BTooltip,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'YoutubeLinks',
  components: {
    BFormInput,
    BSpinner,
    BRow,
    BCol,
    BButton,
    BTooltip,
  },
  props: {
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      val: [],
    }
  },
  computed: {
    ...mapGetters({
      subscription: 'billing/subscription',
      isSubscriptionInactive: 'billing/isSubscriptionInactive',
      isSubscriptionPremium: 'billing/isSubscriptionPremium',
    }),
    valUrl() {
      return this.val.map(i => i.url)
    },
    isValidToSave() {
      return this.isSubscriptionPremium && this.val.every(i => !i.isValidating && i.isValid)
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if (!this.val.length || [...newValue, ''].toString() !== this.val.map(i => i.url).toString()) {
          this.val = [...newValue.map(i => this.getItemParams(i)), this.getItemParams('')]
          this.val.forEach(i => {
            this.validateItem(i.id)
          })
        }
      },
      immediate: true,
    },
    valUrl(newValue) {
      if (newValue[newValue.length - 1]) {
        this.val = [...this.val, this.getItemParams('')]
      }
    },
  },
  methods: {
    getItemParams(url) {
      return {
        id: uuidv4(),
        url,
        isValidating: false,
        isValid: true,
      }
    },
    getYoutubeVideoIdByUrl(url) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      const match = url.match(regExp)
      return (match && match[7].length === 11) ? match[7] : false
    },
    async validateItem(id) {
      const item = this.val.find(i => i.id === id)
      if (!item) {
        return
      }

      const isLastItem = this.val.findIndex(i => i.id === id) + 1 === this.val.length
      if (isLastItem) {
        return
      }

      item.isValidating = true
      const youtubeId = this.getYoutubeVideoIdByUrl(item.url)
      if (youtubeId) {
        const result = await fetch(`https://www.youtube.com/oembed?url=https://youtu.be/${youtubeId}&format=json`)
        if (!result.ok) {
          item.isValid = false
        }
      } else {
        item.isValid = false
      }
      item.isValidating = false
    },
    async onBlurInput(id) {
      this.$root.$emit('bv::hide::tooltip', `youtube-input_${id}`)
      await this.validateItem(id)
      if (this.isValidToSave) {
        this.emitInput()
      }
    },
    onClickRemove(id) {
      this.val = this.val.filter(i => i.id !== id)
      if (this.val.length === 1 && this.val[0].url === '') {
        this.emitInput()
      } else if (this.isValidToSave) {
        this.emitInput()
      }
    },
    emitInput() {
      this.$emit('input', this.val.filter(i => !!i.url).map(i => i.url))
    },
  },
}
</script>
