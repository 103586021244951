import { integer as rule_integer } from 'vee-validate/dist/rules'

export const validatorPositive = value => value >= 0

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const validatorFraction = val => {
  const valArr = val.split('')

  if (valArr.filter(i => i === '/').length === 1) {
    const slashIndex = valArr.findIndex(i => i === '/')
    const valArrBeforeSlash = valArr.filter((i, iIndex) => iIndex < slashIndex)
    const valArrAfterSlash = valArr.filter((i, iIndex) => iIndex > slashIndex)

    if (valArrBeforeSlash.length && valArrAfterSlash.length) {
      return rule_integer.validate(valArr.filter(i => i !== '/').join(''))
    }

    return false
  }

  return false
}
