<template>
  <b-card-actions
    :title="$t('Images')"
    action-collapse
    collapsed
  >
    <template v-slot:after-title>
      <feather-icon
        icon="InfoIcon"
        role="button"
        size="20"
        style="vertical-align: top"
        class="ml-25 text-primary toggle-content"
        @click.stop="setIsInfo([page, 'images'])"
      />
    </template>
    <b-collapse v-model="isInfo[page].images">
      <small class="d-block mb-2">
        {{ $t('infoImages') }}
      </small>
      <images-limitation />
    </b-collapse>
    <images
      :size="{ width: 800, height: 800 }"
      :is-multiple="true"
      :value="value"
      :update-mutation="UPDATE_MENU_ITEM"
      :update-variables="(val) => ({
        updateMenuItemId: this.$route.params.id,
        images: val.map(i => i.id),
      })"
      @input="$emit('input', $event)"
    />
  </b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Images from '@/components/Images.vue'
import { mapGetters, mapActions } from 'vuex'
import { BCollapse } from 'bootstrap-vue'
import ImagesLimitation from '@/components/ImagesLimitation.vue'
import mixinCardCollapsed from '@/mixins/card-collapsed'
import UPDATE_MENU_ITEM from '@/gql/mutation/menuItem/updateMenuItem.gql'

export default {
  name: 'CardImages',
  components: {
    ImagesLimitation,
    BCardActions,
    Images,
    BCollapse,
  },
  mixins: [
    mixinCardCollapsed,
  ],
  props: {
    value: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    page: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      UPDATE_MENU_ITEM,
    }
  },
  computed: {
    ...mapGetters({
      isInfo: 'main/isInfo',
      restaurant: 'restaurant/restaurant',
    }),
  },
  methods: {
    ...mapActions({
      setIsInfo: 'main/setIsInfo',
    }),
  },
}
</script>
