export default {
  props: {
    collapsed: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  watch: {
    collapsed(newValue) {
      if (
        (!newValue && !this.$el.getElementsByClassName('card-content collapse')[0].classList.contains('show'))
        || (newValue && this.$el.getElementsByClassName('card-content collapse')[0].classList.contains('show'))
      ) {
        this.$el.getElementsByClassName('card-header')[0].click()
      }
    },
  },
}
