<template>
  <b-card-actions
    :title="$t('Tags')"
    action-collapse
    collapsed
  >
    <template v-slot:after-title>
      <feather-icon
        icon="InfoIcon"
        role="button"
        size="20"
        style="vertical-align: top"
        class="ml-25 text-primary toggle-content"
        @click.stop="setIsInfo([page, 'tags'])"
      />
    </template>
    <template v-slot:default>
      <b-collapse v-model="isInfo[page].tags">
        <small class="d-block mb-2">
          {{ $t('infoTags') }}
        </small>
      </b-collapse>
      <div>
        <b-dropdown
          ref="dropdown-tags"
          variant="outline-primary"
          class="dropdown-tags mr-50"
          no-caret
          size="sm"
          dropup
          @shown="onShownDropdown"
        >
          <template #button-content>
            <feather-icon
              icon="PlusIcon"
              role="button"
            />
          </template>
          <div class="dropdown-tags__items">
            <b-dropdown-item
              v-for="tag in tagsToChooseFiltered"
              :key="tag.id"
              @click="addTagFromList(tag)"
            >
              {{ tag.name }}
            </b-dropdown-item>
          </div>
          <div :class="['pr-75 pb-25 pl-75', tagsToChooseFiltered.length ? 'pt-75' : 'pt-25']">
            <validation-provider
              #default="{ errors, failedRules }"
              name="add_tag"
              :rules="`max:${textLimit}`"
            >
              <b-form-input
                ref="dropdown-tags__input"
                :value="newTagName"
                :placeholder="$t('cardTagsNamePlaceholder')"
                :state="errors.length ? false : null"
                @input="onInputNewTagName($event)"
                @keydown.enter.prevent="addTag"
              />
              <small class="text-danger">
                <template v-if="failedRules.max">{{ $t('validationErrorMaxTag', { slot: textLimit }) }}</template>
                <template v-else>{{ errors[0] }}</template>
              </small>
            </validation-provider>
          </div>
        </b-dropdown>
        <b-badge
          v-for="tag in value"
          :key="tag.id"
          variant="primary"
          class="mr-50"
          style="margin-top: 4.5px"
        >
          {{ tag.name }}
          <feather-icon
            icon="XIcon"
            role="button"
            @click="removeTag(tag)"
          />
        </b-badge>
      </div>
    </template>
  </b-card-actions>
</template>

<script>
import {
  BCollapse,
  BFormInput,
  BBadge,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { mapGetters, mapActions } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import { ValidationProvider } from 'vee-validate'
import { max } from '@validations'
import mixinCardCollapsed from '@/mixins/card-collapsed'

export default {
  name: 'CardTags',
  components: {
    BCollapse,
    BFormInput,
    BBadge,
    BDropdown,
    BDropdownItem,
    BCardActions,

    // validations
    ValidationProvider,
  },
  mixins: [
    mixinCardCollapsed,
  ],
  props: {
    value: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    page: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      maxTags: 5,
      newTagName: '',
      textLimit: 25,

      // validation rules
      max,
    }
  },
  computed: {
    ...mapGetters({
      isInfo: 'main/isInfo',
      menuItems: 'menuManagement/menuItems',
    }),
    tagsToChoose() {
      return [...new Map(this.menuItems.flatMap(i => i.tags).map(i => [i.name, i])).values()]
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    },
    tagsToChooseFiltered() {
      return this.tagsToChoose
        .filter(i => !this.value.find(j => j.name === i.name))
        .filter(i => i.name.toLowerCase().includes(this.newTagName.toLowerCase()))
    },
  },
  methods: {
    ...mapActions({
      setIsInfo: 'main/setIsInfo',
    }),

    onShownDropdown() {
      this.$refs['dropdown-tags__input'].focus()
    },
    recalculateDropdownPosition() {
      this.$refs['dropdown-tags'].hide()
      this.$refs['dropdown-tags'].show()
    },
    onInputNewTagName(event) {
      this.newTagName = event
      this.recalculateDropdownPosition()
    },
    addTag() {
      if (this.value.length < this.maxTags && this.newTagName !== '' && this.newTagName.length <= this.textLimit) {
        if (!this.value.find(i => i.name === this.newTagName)) {
          this.$emit('input', ['add', { id: uuidv4(), name: this.newTagName }])
        }
        this.newTagName = ''
        this.recalculateDropdownPosition()
      }
    },
    addTagFromList(tag) {
      if (this.value.length < this.maxTags) {
        if (!this.value.find(i => i.name === tag.name)) {
          this.$emit('input', ['add', { id: uuidv4(), name: tag.name }])
        }
        this.newTagName = ''
        this.$nextTick(() => {
          this.recalculateDropdownPosition()
        })
      }
    },
    removeTag(tag) {
      this.$emit('input', ['remove', tag])
    },
  },
}
</script>

<style lang="sass">
  .dropdown-tags
    &__items
      max-height: 116px
      width: 218px
      overflow-y: auto
</style>
